html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Raleway', sans-serif;
}

li {
    list-style: none;
    text-decoration: none;
}
a {
    text-decoration: none;
}


*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 769px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 72px;
    margin-bottom: 45px;
    font-family: 'Roboto Serif';
    margin-top: 90px;
}

h1 span {
    font-size: 65px;
    font-weight: 800;
}

h2 {
    font-family: 'Roboto Serif';
    font-style: normal;
    font-weight: 800;
    font-size: 52px;
    line-height: 60px;
    margin-bottom: 25px;
}

h3 {
    font-family: 'Roboto Serif';
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
}

p {
    font-size: 18px;
    font-weight: 500;
    color: #2C4167;
    line-height: 30px;
}
p:not(:last-child) {
    padding-bottom: 25px;
}



/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    position: relative;
    z-index: 3;
    margin-bottom: 20px;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    padding: 0;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }



.logo-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    align-items: center;
    color: #000;
    font-family: 'Roboto Serif';
    font-weight: 800;
}

.logo-wrapper a {
    color: #000;
    display: flex;
    align-items: center;
    gap: 5px;
}

.logo {
    color: #000;
    font-family: 'Roboto Serif';
    font-weight: 800;
}

.logo a {
    color: #000;
    display: flex;
    align-items: center;
    gap: 5px;
}

.nav-bar ul {
    display: flex;
    justify-content: space-around;
    gap: 110px;
}

.nav-bar ul li a {
    font-weight: 700;
    color: #000;
    transition: color 0.3s;
}

.nav-bar ul li a:hover { 
    color: #0C81F6;
;
}

.hero {
    display: grid;
    grid-template-columns: 1fr 35%;
}

.hero-img-mobile {
    display: none;
}

.hero-image {
    background-image: url(../image/hero-image.webp);
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 35px;
    background-position: center;
}

.hero-content p {
    padding-right: 81px;
    margin-left: 40px;
}

.btn {
    background-color: #0C81F6;
    border-radius: 80px;
    box-shadow: 0px 4px 29px 0px #394C7133;
    height: 82px;
    padding: 3px;
    width: 300px;
    padding-top: 20px;
    transition: background-color 0.3s;
    margin-left: 40px;
    margin-top: 45px;
}

.btn:hover {
    background-color: #1e6ebf;
}

.cta {
    align-items: center;
    background-color: transparent;
    color: #fff;
    display: flex;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%;
    z-index: 2;
    justify-content: center;
}

section {
    margin-top: 110px;
}

.what-is {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.what-is-image {
    background-image: url(../image/what-is.webp);
    background-size: contain;
    background-repeat: no-repeat;
    margin: 25px;
}

#what-is-crypto-credit-card {
    position: relative;
}

#what-is-crypto-credit-card::before {
    position: absolute;
    content: "";
    width: 60px;
    height: 60px;
    background-color: #7EEC9A;
    border-radius: 21px;
    z-index: -1;
    top: -10px;
    left: -26px;
}


.types {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.types div {
    border-left: 2px solid black;
    padding-left: 40px;
    padding-right: 50px;
}

.arrow1 {
    position: relative;
}
.arrow1::before {
    position: absolute;
    content: "";
    background-image: url(../image/arrow1.svg);
    background-repeat: no-repeat;
    width: 693px;
    background-size: contain;
    height: 420px;
    top: -247px;
    left: 433px;
    z-index: -1;
}

.arrow2 {
    position: relative;
}
.arrow2::before {
    position: absolute;
    content: "";
    background-image: url(../image/arrow2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 400px;
    height: 131px;
    top: -109px;
    left: 363px;
}


.features-wrapper {
    background-color: #F8FBFD;
    border-radius: 20px;
    padding: 90px 80px;
    position: relative;
}

.features-wrapper::before {
    position: absolute;
    content: "";
    background-image: url(../image/square1.png);
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    left: -110px;
    top: -60px;
}

.features-wrapper::after {
    position: absolute;
    content: "";
    background-image: url(../image/square2.png);
    background-repeat: no-repeat;
    width: 43px;
    height: 44px;
    right: -110px;
    top: 380px;
}

h4 {
    position: relative;
    margin-bottom: 25px;
    padding-left: 71px;
    padding-top: 15px;
    margin-top: 60px;
}

.h4 {
    border-left: 3px solid #000;
    padding-left: 20px;
}

#features {
    margin-top: 20px;
}

#fees {
    padding-top: 80px;
    padding-bottom: 29px;
}

.automatic::before {
    position: absolute;
    content: "";
    background-image: url(../image/btc-coin.webp);
    background-repeat: no-repeat;
    width: 54px;
    height: 48px;
    left: 0;
    top: 0px;
}

.profile::before {
    position: absolute;
    content: "";
    background-image: url(../image/profile.webp);
    background-repeat: no-repeat;
    width: 48px;
    height: 49px;
    left: 0;
    top: 0;
}

.security::before {
    position: absolute;
    content: "";
    background-image: url(../image/security.webp);
    background-repeat: no-repeat;
    width: 52px;
    height: 46px;
    left: 0;
    top: 0;
}

.rewards::before {
    position: absolute;
    content: "";
    background-image: url(../image/star.webp);
    background-repeat: no-repeat;
    width: 52px;
    height: 46px;
    left: 0;
    top: 0;
}

/* TABLE  */

table{
    border: 0;
    border-collapse: separate;
    border-spacing: 0 22px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    margin-top: 50px;
}
.main-table tbody tr {
    border-radius: 28px;
    margin: 15px 0;
    padding: 5px 5px 5px 20px;
  }
  .main-table td:first-child {
    border-left: 2px solid;
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .main-table td:last-child {
    border-right: 2px solid;
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .main-table td {
    border-color: transparent;
    border-style: solid none;
    border-width: 1px;
    padding: 10px;
    position: relative;
    text-align: left;
  }
.inner-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 70px;
    padding-top: 25px;
}

.inner-table a {
    color: #fff;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
}

.inner-table td {
    padding: 40px;
}

.inner-table td:not(:first-of-type) {
    position: relative;
}

.logo-inner {
    background-color: #F1F8FC;
    border-radius: 20px;
    position: relative;
    transition: background-color 0.3s;
}

tr:first-of-type .logo-inner::before {
    content: "1";
}

.logo-inner::before{
    position: absolute;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background: #77E79A;
    text-align: center;
    padding: 8px;
    top: 3px;
    left: 3px;
    font-family: 'Roboto Serif';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}

tr:not(:first-of-type) .logo-inner::before {
    background: #000;
    color: #fff;
}

tr:nth-of-type(2) .logo-inner::before {
    content:"2";
}

tr:nth-of-type(3) .logo-inner::before {
    content:"3";
}

tr:nth-of-type(4) .logo-inner::before {
    content:"4";
}

tr:nth-of-type(5) .logo-inner::before {
    content:"5";
}
tr:nth-of-type(6) .logo-inner::before {
    content:"6";
}
tr:nth-of-type(7) .logo-inner::before {
    content:"7";
}


.row {
    border: 2px solid #000;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 2px;
}

.btn.tbl {
    width: 200px;
    margin-left: 0;
    padding: 20px;
    height: auto;
    position: relative;
}

.row:hover .logo-inner {
    background-color: #F3FFF7;
}

.row:hover .btn.tbl {
    background: #77E79A;
}

.btn.coinbase::before {
    position: absolute;
    content: "";
    background-image: url(../image/coinbase.webp);
    background-repeat: no-repeat;
    width: 150px;
    height: 50px;
    left: 32px;
    top: -50px;
}

.unbanked {
    margin-top: 25px;
}

#best-cards-list {
    text-align: center;
}



.btn.unbanked::before {
    position: absolute;
    content: "";
    background-image: url(../image/blockcard.webp);
    background-repeat: no-repeat;
    width: 190px;
    height: 50px;
    left: 0;
    top: -50px;
}

.btn.wirex::before {
    position: absolute;
    content: "";
    background-image: url(../image/wirex.webp);
    background-repeat: no-repeat;
    width: 150px;
    height: 50px;
    left: 18px;
    top: -50px;
}

.wirex {
    margin-top: 20px;
}

.blockfi {
    margin-top: 20px;
}

.btn.blockfi::before {
    position: absolute;
    content: "";
    background-image: url(../image/blockfi.webp);
    background-repeat: no-repeat;
    width: 190px;
    height: 50px;
    left: 0;
    top: -50px;
}

.btn.bitpay::before {
    position: absolute;
    content: "";
    background-image: url(../image/bitpay.webp);
    background-repeat: no-repeat;
    width: 140px;
    height: 50px;
    left: 37px;
    top: -55px;
}

.bitpay {
    margin-top: 0;
}

.btn.crypto::before {
    position: absolute;
    content: "";
    background-image: url(../image/crypto.com.webp);
    background-repeat: no-repeat;
    width: 190px;
    height: 50px;
    left: 15px;
    top: -50px;
}
.crypto {
    margin-top: 15px;
}

.btn.binance::before {
    position: absolute;
    content: "";
    background-image: url(../image/binance.webp);
    background-repeat: no-repeat;
    width: 190px;
    height: 50px;
    left: 0;
    top: -50px;
}
.binance {
    margin-top: 15px; 
}

.inner-table li {
    font-size: 16px;
    font-weight: 600;
    color: #2C4167;
    line-height: 20px;
    margin-bottom: 20px;
    padding-left: 25px;
    position: relative;
}

.inner-table li::before {
    content: "";
    position: absolute;
    background-color:#77E79A;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: 0;
    top: 40%;
}

.inner-table div[data-name]:before {
    color: #4F4F4F;
    content: attr(data-name);
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    position: absolute;
    text-transform: uppercase;
    top: 15px;
}

.bold {
    font-weight: 700;
    font-size: 22px;
    color: #000 !important;
}

[data-name="Cashback"] {
    padding-right: 40px !important;
}

@media only screen and (max-width: 990px) {
    .arrow1::before {
        height: 297px !important;
    left: 176px !important;
    top: -208px !important;
    transform: rotate(354deg) !important;
    }
    .arrow2::before {
        left: 160px !important;
    }
}

@media only screen and (max-width: 1000px) {
    /* .glance-beyond {
        grid-template-columns: 1fr !important;
        grid-template-rows: 1fr 1fr !important;
    }
    .glance-img {
        order: -1;
    } */
    .glance-img::before {
        width: 640px !important;
        top: 120px !important;
    } 
    .main-table .row{
        display: grid;
    }
    .inner-grid{
        grid-template-columns: 1fr;
        gap: 25px;
        padding-top: 4px;
    }
    .main-table tbody tr{
        padding: 10px;
        border-radius: 20px
    }
    .inner-table td{
        padding: 0;
    }
    
    .inner-table td+td{
        margin-top: 10px;
    }
    .main-table td:first-child{
        border-left: none;
        border-top: none;
        border-bottom: none;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .main-table td:last-child {
        border-right: none;
        border-top: none;
        border-bottom: none;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    .btn.tbl{
        margin: 50px auto 0 auto!important;
    }
    .logo-inner{
        padding: 40px!important;
    }
    .logo-inner::before{
        top: -4px;
        left: -4px;
    }

}
/* td expand */
.t-expand{
    float: left;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
    text-align: left;
  }
  .t-expand-title{
    font-size: 0;
    display: none;
  }
  
  @media only screen and (max-width: 1000px) {
    .t-expand.collapsed .t-expand-body {
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
    .t-expand.expanded .t-expand-body{
      height: auto;
      opacity: 1;
      transition: opacity 0.3s ease-out;
      padding: 40px 25px 25px 25px;
      border: 1px solid #D1DFE9;
      box-sizing: border-box;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    .t-expand.expanded .t-expand-body>div{
        position: relative;
        margin-top: 15px;
    }
    .inner-table .t-expand.expanded div[data-name]:before{
        top: -35px;
    }
    .t-expand-title{
      cursor: pointer;
    }
    .t-expand-body ul {
      display: block;
    }
   
    .t-expand-title{
      position: relative;
      display: block;
      background: #D0E0EB;
      border-radius: 28px;
      width: 100%;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      line-height: 30px;
      color: #4F4F4F;
      padding: 8px 15px;
    }
    .expanded .t-expand-title{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .t-expand-btn{
      position: absolute;
      right: 30px;
      top: 12px;
    }
    .expanded .t-expand-btn{
        top: 17px;
    }
    .t-expand-body-btn {
      float: right;
      height: 20px;
      position: relative;
      width: 30px;
  }
    .t-expand-btn:after {
      border-left: 2px solid #4F4F4F;
      border-top: 2px solid #4F4F4F;
      content: "";
      height: 10px;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
      -webkit-transition: -webkit-transform .5s ease-in-out;
      -ms-transition: -ms-transform .5s ease-in-out;
      transition: transform .5s ease-in-out;
      width: 10px;
    }
    .expanded .t-expand-btn:after {
      transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  
/* ENDS TABLE  */

section a {
    font-weight: bold;
    text-decoration: underline;
    color: #2C4167;
}

.glance-beyond {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.glance-img {
    position: relative;
}

.glance-img::before {
    content: "";
    position: absolute;
    background-image: url(../image/glance-beyond.webp);
    background-size: contain;
    background-repeat: no-repeat;
    width: 800px;
    height: 525px;
    z-index: -1;
    top: 30px;
    right: -131px;
}

/* FAQ  */

.faq-wrapper {
    background-color: #000;
    color: #fff;
    border-radius: 20px;
    padding: 90px;
}
.faq-wrapper h3 {
    font-size: 30px !important;
    margin-top: 25px;
    position: relative;
    z-index: 2;
    padding-left: 65px;
    padding-bottom: 10px;
}

.faq-wrapper p {
    color: #B9B9B9 !important;
    padding-bottom: 40px;
}

.faq-wrapper .faq:not(:last-of-type) {
    border-bottom: 1px solid #565656;
}

.center {
    text-align: center;
}

.faq h3::before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    width: 42px;
    height: 42px;
    background-repeat: no-repeat;
}

.faq:first-of-type h3::before {
    background-image: url(../image/credit-card.webp);
    top: 2px;
}

.faq:nth-of-type(2) h3::before {
    background-image: url(../image/cryptocurrency.webp);
    top: 5px;
}
.faq:nth-of-type(3) h3::before {
    background-image: url(../image/visa.webp);
    top: 7px;
}
.faq:nth-of-type(4) h3::before {
    background-image: url(../image/wallet.webp);
    top: 5px;
}



.about-us {
    text-align: center;
    background-color: #F8FBFD;
    border-radius: 20px;
    padding: 70px 100px;
}

.about-us-wrapper {
    margin-top: 50px;
}

a {
   
}
.contact-us {
    align-items: center;
    background-color: transparent;
    color: #fff;
    display: flex;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%;
    z-index: 2;
    cursor: pointer;
    justify-content: center;
  
}

.btn.contact-btn {
    padding-top: 29px;
    margin-top: 20px;
    position: relative;
    margin-left: 0;
    text-decoration: none !important;
    color: inherit !important;
}

footer .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copyright {
    font-size: 16px;
    color: #272727;
}

/* swiper  */
.swiper-wrapper {
    display: initial;
}

.swiper-pagination {
    display: none;
}

/* .swiper {
    overflow: initial;
} */

.body-wrapper {
    overflow: hidden;
}

@media only screen and (max-width: 1200px) {
    .arrow1::before {
        height: 385px;
        top: -235px;
        left: 308px;
    }

    .arrow2::before {
        left: 265px;
    }
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 24px;
        line-height: 38px;
        margin-bottom: 24px;
        text-align: center;
        margin-top: 20px;
    }

    h2 {
        font-size: 26px;
        line-height: 32px;
    }

    h3 {
        font-size: 26px
    }

    h1 span {
        font-size: 29px;
    }

    .container {
        padding: 0 22px;
    }

    .hero-image {
        display: none;
    }

    .hero-content {
        text-align: center;
    }

    .hero-content p {
        padding: 0;
        margin: 0;
    }

    .btn {
        margin: 0 auto !important;
    }

    .hero-img-mobile {
        background-image: url(../image/hero-image.webp);
        background-repeat: no-repeat;
        background-size: contain;
        margin: 20px auto 30px;
        background-position: center;
        display: block;
        width: 300px;
        height: 320px;
    }

    .fees h3 {
        padding-top: 0 !important;
    }

    .fees {
        padding: 33px 36px;
        background-color: #F8FBFD;
        border-radius: 20px;
        margin-top: 40px;
    }

    .nav-bar {
        display: none;
        font-family: 'Raleway';
    }
    .logo-wrapper {
        justify-content: center;
        padding: 0;
    }

    .logo {
        padding: 20px 40px;
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: #EDF3F6;
        border-radius: 0 0 10px 10px;
    }

    .hero p {
        padding-bottom: 30px;
    }

    .cta {
        justify-content: center;
    }
    
    .hero {
        grid-template-columns: 1fr;
    }

    .what-is-image {
        display: none;
    }

    .what-is {
        grid-template-columns: 1fr;
    }

    .types {
        
        grid-template-columns: 1fr;
    }

    .credit {
        margin-bottom: 60px;
    }

    .arrow1::before,
    .arrow2::before {
        display: none;
    }

    .features-wrapper {
        background-color: #fff;
        padding: 0;
    }

    #features-fees,
    #fees {
        text-align: center;
    }

    .features-n-fees {
        padding: 33px 36px;
        background-color: #F8FBFD;
        border-radius: 20px;
    }
    .h4 {
        border: 0;
        padding-left: 0;
        padding: 0px 36px 30px;
        background-color: #F8FBFD;
        border-radius: 20px;
    }

    .swiper-wrapper {
        box-sizing: border-box !important;
        display: flex;
    }

    .swiper-pagination-bullet-active {
        background-color: #77E79A;
    }

    .swiper-pagination-bullet {
        color: transparent;
    }

    h4 {

        margin-top: 35px !important;
    }

    .swiper-pagination {
        position: initial;
        padding-top: 9px;
       display: block;
    }

    #features {
        margin-bottom: 20px;
        text-align: center;
    }
    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 10px;
    }

    #best-cards-list {
        text-align: center;
    }

    .glance-beyond {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .glance-img {
        order: -1;
    }

    .glance-img::before {
        top: 30px !important;
        right: -131px !important;
        width: 800px !important; 
    }

    #future-expectations {
        margin-top: 40px;
    }

    .faq-wrapper {
        padding: 30px 22px;
    }

    .center {
        text-align: left;
    }

    .faq-wrapper h3 {
        font-size: 22px !important;
        line-height: 40px;
    }

    footer .container {
        flex-direction: column;
        padding: 20px;
        gap: 6px;
    }

    footer .site-logo img {
        display: none;
    }
    
    .about-us {
        padding: 48px 17px;
    }
}

@media only screen and (max-width: 500px) {
    .glance-img::before {
        width: 653px !important;
        right: -99px !important;
    }
    .glance-beyond {
        grid-template-rows: 377px 1fr;
    }
}
@media only screen and (max-width: 450px) {
    .glance-img::before {
        width: 595px !important;
        right: -99px !important;
    }
    .glance-beyond {
        grid-template-rows: 351px 1fr;
    }
    .faq-wrapper {
        padding: 30px 0px;
    }
}
@media only screen and (max-width: 400px) {
    .glance-img::before {
        width: 520px !important;
        right: -80px !important;
    }
    .glance-beyond {
        grid-template-rows: 321px 1fr;
    }
}

@media only screen and (max-width: 365px) {
    .glance-img::before {
        width: 450px !important;
        right: -78px !important;
    }
    .glance-beyond {
        grid-template-rows: 270px 1fr;
    }
}


